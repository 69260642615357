<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('my_courses')"
                        :isFilter="false"
                        :isColumns="false"
                />
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('my_courses')"
                              :isFilter="false"
                              :isColumns="false"
                />
            </template>
            <div>
                <loading v-show="loading"/>
                <div v-show="!loading" v-if="data && data.courses">
                    <b-table
                        bordered
                        striped
                        responsive
                        :items="data.courses"
                        :fields="fields"
                        show-empty
                        class="mb-5 table-dropdown no-scrollbar border rounded">
                    </b-table>

                    <div v-if="data && data.lms_link">
                        <div class="row">
                            <div class="col-12"><a :href="data.lms_link.url" target="_blank"><img style="max-height: 48px" :src="data.lms_link.logo"/></a></div>
                        </div>
                    </div>

                    <div v-if="user && user.get_course_confirmation">
                        <div class="row">
                            <div class="col-6 col-xs-12">
                                <b-button type="button"
                                          variant="success"
                                          size="lg"
                                          block
                                          @click="confirm('E')"
                                >
                                    {{ $t('approve').toUpper() }}
                                </b-button>
                            </div>
                            <div class="col-6 col-xs-12">
                                <b-button type="button"
                                          variant="danger"
                                          size="lg"
                                          class="float-right"
                                          block
                                          @click="confirm('H')"
                                >
                                    {{ $t('reject').toUpper() }}
                                </b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </app-layout>
    </div>
</template>
<script>

// Template
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";

// Components
import Loading from '@/components/elements/Loading'

// Services
import StudentCourseService from "@/services/StudentCourseService";
import CourseRegistrationService from "@/services/CourseRegistration";

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        Loading,
    },
    metaInfo() {
        return {
            title: this.$t("my_courses"),
        }
    },
    data() {
        return {
            loading: false,
            user: {},
            studentProgramId: null,
            data: null,
            fields: [
                {
                    key: 'course_code',
                    label: this.toUpperCase('course_code')
                },
                {
                    key: 'course_name',
                    label: this.toUpperCase('course_name')
                },
                {
                    key: 'section_number',
                    label: this.toUpperCase('section')
                },
                {
                    key: 'created_at',
                    label: this.toUpperCase('created_at'),
                    formatter: this.formatDate,
                    thClass: 'width-150'
                },
            ],
        }
    },
    mounted() {
        this.user = this.$store.getters['auth/getUser'];
        this.getMyCourses()
    },
    methods: {
        getMyCourses() {
            this.loading = true;
            StudentCourseService.getActiveCourses()
                .then(response => {
                    this.data=response.data.data;
                })
                .catch((e) => {
                    this.showErrors(e);
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        confirm(value){
            let msg='are_you_sure_to_approve';
            if(value=='H'){
                msg='are_you_sure_to_reject';
            }

            this.$swal.fire({
                text: this.$t(msg),
                showCancelButton: true,
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no')
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        CourseRegistrationService.confirm({value: value})
                            .then(()=>{
                            if(value=='E'){
                                this.$toast.success(this.$t('course_registration_is_confirmed'));
                            }
                            else {
                                this.$toast.success(this.$t('course_registration_is_rejected'));
                            }
                            this.$store.dispatch("auth/initUser");

                            this.routeDashboard();
                        }).catch(err=>this.showErrors(err));
                    }
                })
        },
        routeDashboard(){
            setTimeout(()=>{
                this.$router.push('/dashboard')
            },2000)
        }

    }
}
</script>
